varying vec3 vPos;
uniform float uTime;
uniform float uScroll;

void main()
{
    // Distance from the center of the point (for point particles)
    float distanceToCenter = distance(gl_PointCoord, vec2(0.5));
    float strength = clamp(0.18 / distanceToCenter - 0.5, 0.0, 2.0);  // Clamp the strength value - default clamp 0.18

    // Define colors for the transition
    vec3 color1 = vec3(1.0, 0.0, 2.5);  // Red
    vec3 color2 = vec3(1.0, 0.0, 3.0);  // Green
    vec3 color3 = vec3(0.4, 0.0, 3.0);  // Blue

    // Slow down the transition by multiplying uTime by a smaller factor (e.g., 0.3)
    float timeFactor = (sin(uTime * 0.3 + vPos.x + vPos.y + vPos.z) * 1.7) + 3.9;

    // Normalize the timeFactor to cycle between 0 and 3 (for three transitions)
    float transitionFactor = mod(timeFactor, 3.0);

    vec3 color;

    // Adjust the logic to properly handle three color transitions
    if (transitionFactor < 1.0) {
        color = mix(color1, color2, transitionFactor);  // Red to Green
    } else if (transitionFactor < 2.0) {
        color = mix(color2, color3, transitionFactor - 1.0);  // Green to Blue
    } else {
        color = mix(color3, color1, transitionFactor - 2.0);  // Blue to Red
    }

    // Set the final fragment color with adjusted strength
    gl_FragColor = vec4(color * strength, 0.07);  // Keep alpha constant, apply strength to color
}
