uniform sampler2D uPositions; // RenderTarget containing the transformed positions
uniform float uSize;
uniform float uPixelRatio;
uniform vec2 uResolution;
uniform float uScroll;
uniform float uTime; // Uniform for tracking time
varying vec3 vPos;

void main() {

  // Fetch the original position of the particle
  vec3 pos = texture2D(uPositions, position.xy).xyz;


  
  // Apply small individual circular motion around each particle's local position
  float radius = 0.03;  // Orbit radius for each particle
  float speed = 1.0;    // Speed of orbit for each particle

  // Keep the particle rotating around its local position (does not affect the overall shape)
  pos.x += sin(uTime * speed + position.x * 1.0) * radius;
  pos.y += cos(uTime * speed + position.y * 2.0) * radius;






  // Proceed with the remaining code (size adjustments and projection)
  float range = 1.0 / uTotalModels;
  float customSize = uSize;

  vec4 modelPosition = modelMatrix * vec4(pos, 1.0);
  vec4 viewPosition = viewMatrix * modelPosition;
  vec4 projectionPosition = projectionMatrix * viewPosition;

  // Adjust particle size based on scroll (optional) - Default: 7.0, 10.0 - 10.0, 5.0 - 5.0, 20.0 - 20.0, 15.0
  
      if (uScroll < range) {
        customSize = mix(7.0, 7.0, uScroll * uTotalModels);
      } else if (uScroll < range * 2.0) {
        customSize = mix(7.0, 7.0, (uScroll - range) * uTotalModels);
      } else if (uScroll < range * 3.0) {
        customSize = mix(7.0, 7.0, (uScroll - range * 2.0) * uTotalModels);
      } else {
        float scroll = max((uScroll - range * 3.0), (uScroll - range * 3.0) * uTotalModels);
        customSize = mix(7.0, 15.0, scroll);
      }


  gl_Position = projectionPosition;
  gl_PointSize = customSize * uResolution.y * 0.0013;
  gl_PointSize *= (1.0 / -viewPosition.z);

  vPos = pos;  // Pass the modified position to the fragment shader
}


