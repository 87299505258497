const numStars = 150; // Number of stars in each set, reduced by half
const backgroundStarsContainer = document.getElementById('background-stars');
const midgroundStarsContainer = document.getElementById('midground-stars');
const foregroundStarsContainer = document.getElementById('foreground-stars');




// Function to create a star element with random size, position, and animation
function createStar(container, isForeground) {
    const star = document.createElement('div');
    star.classList.add('star');
    
    // Random size and initial position
    const size = Math.random() * 3 + 1; // Random size between 2px to 8px (double original size)
    const posX = Math.random() * window.innerWidth;
    const posY = Math.random() * window.innerHeight;

    // Set size and position
    star.style.width = `${size}px`;
    star.style.height = `${size}px`;
    star.style.left = `${posX}px`;
    star.style.top = `${posY}px`;

    // Apply random color transition speed
    const duration = Math.random() * 5 + 5; // Between 5s and 10s for color change
    star.style.animation = `individualColorChange ${duration}s infinite alternate`;

    container.appendChild(star);

    return {
        element: star,
        initialPosX: posX,
        initialPosY: posY,
        size: size, // Keep track of initial size
        radius: Math.random() * 20 + 10, // Random small radius for circular motion
        angle: Math.random() * Math.PI * 2, // Random start angle
        speed: Math.random() * 0.05 + 0.01, // Random speed for rotation
        lastPosX: posX, // To track position for cursor-based movement
        lastPosY: posY,
        currentPosX: posX, // Track current position for smooth movement
        currentPosY: posY
    };
}

// Create an array to hold stars data
let backgroundStars = [];
let midgroundStars = [];
let foregroundStars = [];

// Create stars for each container
for (let i = 0; i < numStars; i++) {
    backgroundStars.push(createStar(backgroundStarsContainer, false)); // Background stars
    midgroundStars.push(createStar(foregroundStarsContainer, true));  // Midground stars
    foregroundStars.push(createStar(foregroundStarsContainer, false));  // Foreground stars
}

// Variables to track mouse movement
let mouseX = window.innerWidth / 2;
let mouseY = window.innerHeight / 2;
let lastMouseX = mouseX;
let lastMouseY = mouseY;
let deltaX = 0;
let deltaY = 0;
let cursorInWindow = true; // Track if cursor is in window

// Update mouse position on move
document.addEventListener('mousemove', (e) => {
    if (cursorInWindow) {
        deltaX = e.clientX - lastMouseX;
        deltaY = e.clientY - lastMouseY;
        mouseX = e.clientX;
        mouseY = e.clientY;
        lastMouseX = mouseX;
        lastMouseY = mouseY;
    }
});

// Detect when cursor leaves the window
document.addEventListener('mouseleave', () => {
    cursorInWindow = false;
    deltaX = 0;
    deltaY = 0;
});

// Detect when cursor re-enters the window
document.addEventListener('mouseenter', (e) => {
    cursorInWindow = true;
    lastMouseX = e.clientX;
    lastMouseY = e.clientY;
});

// Function to move stars in circular motion and cursor-based movement
function moveStars(stars, directionMultiplier) {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const edgeBuffer = 0.2; // 20% screen edge

    stars.forEach(starData => {
        const star = starData.element;
        const { initialPosX, initialPosY, radius, speed, size } = starData;

        // Continuous circular motion (always happening)
        starData.angle += speed; // Update angle for circular motion

        const circularX = initialPosX + radius * Math.cos(starData.angle);
        const circularY = initialPosY + radius * Math.sin(starData.angle);

        // Cursor-based movement (only when cursor is in window and moving)
        let movementX = 0;
        let movementY = 0;

        // Scale up particles movement to cover more distance (at least half the cursor's movement)
        const movementScale = 3.5; // Move particles half the distance of the cursor
        if (Math.abs(deltaX) > 0 || Math.abs(deltaY) > 0) {
            movementX = deltaX * movementScale * directionMultiplier;
            movementY = deltaY * movementScale * directionMultiplier;
        }

        // Smooth transition to new position
        starData.currentPosX += (movementX - starData.currentPosX) * 0.01; // Smooth easing for x
        starData.currentPosY += (movementY - starData.currentPosY) * 0.01; // Smooth easing for y

        // Calculate final position (circular motion + cursor movement)
        let finalX = (circularX + starData.currentPosX + screenWidth) % screenWidth;
        let finalY = (circularY + starData.currentPosY + screenHeight) % screenHeight;

        // Edge detection and size increase
        if (
            finalX < screenWidth * edgeBuffer || 
            finalX > screenWidth * (1 - edgeBuffer) ||
            finalY < screenHeight * edgeBuffer || 
            finalY > screenHeight * (1 - edgeBuffer)
        ) {
            star.style.width = `${size * 1.25}px`; // Increase size by 25%
            star.style.height = `${size * 1.25}px`;
        } else {
            star.style.width = `${size}px`; // Reset to original size
            star.style.height = `${size}px`;
        }

        // Update star position
        star.style.left = `${finalX}px`;
        star.style.top = `${finalY}px`;
    });
}

// Update stars' movement every frame
function updateStars() {
    moveStars(backgroundStars, 1);   // Background stars move with cursor
    moveStars(midgroundStars, -2);   // Background stars move with cursor
    moveStars(foregroundStars, 2);  // Foreground stars move opposite to cursor
    requestAnimationFrame(updateStars); // Continue updating
}

updateStars(); // Start the movement
